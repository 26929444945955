@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/ibm-plex/IBMPlexSans-Italic.ttf) format('truetype');
}


@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/ibm-plex/IBMPlexSans-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/ibm-plex/IBMPlexSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/ibm-plex/IBMPlexSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/ibm-plex/IBMPlexSans-Bold.ttf) format('truetype');
}
